import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"
import tagManager from "../utils/tag-manager"
import useHeroImage from "../hooks/useHeroImage"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import ProductSection from "../components/dynamic-sections/ProductSection"
import SixImageSection from "../components/dynamic-sections/SixImageSection"

/** svg */
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const productsPage: React.FC<Props> = (props) => {
  const { titleOne, subTitleOne, imageOne, twitterCardOne, twitterOpenGraphOne } = useHeroImage()

  tagManager.pushOnce({
    event: "view-home",
  })
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/",
          name: "Norhart Supply Products",
          image: `${config.siteMetadata.siteUrl}/home/norhart-home-hero.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Norhart Supply | Products"
      description="We Provide Building Supplies For Multifamily Contractors"
      keywords=""
      imageTwitter={`${config.siteMetadata.siteUrl}${twitterCardOne}`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}${twitterOpenGraphOne}`}
      colorPalette={colorPalette}
    >

      <HeroTopSection
        title="Construction Supplies"
        subtitle="For Your Multifamily Projects"
        imageTitle="Norhart Supply"
        image="/home/norhart-home-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="We Provide Building Supplies"
        tagLine="For Multifamily Contractors"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <SixImageSection
        backgroundColorOne="#E5E8e9"
        backgroundColorTwo="#EFF2F4"
        colorPalette={colorPalette}
        fontTextColor="#365c70"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Supply"
        title="Products"
        subtitle="Norhart Supply continually provides innovative solutions for our customers and their multifamily construction needs. Our inventory offers a wide range of products."
        pillActive=""

        imageTitleOne="HVAC"
        imageOne="/hvac/2.jpg"
        imageOneAlt="HVAC"
        imageOnePill=""
        imageOneText="Norhart Supply provides the best in home heating and systems with top of the line HVAC systems, furnaces, air conditioners, and much more."
        buttonOneName ="View Products"
        buttonOneUrl="/hvac/"

        imageTitleTwo="Plumbing"
        imageTwo="/plumbing/1.jpg"
        imageTwoAlt="Plumbing"
        imageTwoPill=""
        imageTwoText="Norhart Supply offers a large inventory of plumbing supplies including plumbing fixtures in Minneapolis, MN. Contact us today for your project."
        buttonTwoName ="View Products"
        buttonTwoUrl="/plumbing/"

        imageTitleThree="Flooring"
        imageThree="/flooring/2.jpg"
        imageThreeAlt=""
        imageThreePill=""
        imageThreeText="Find all flooring styles including hardwood floors, carpeting, laminate, vinyl and tile flooring. Get the best flooring products from Norhart Supply"
        buttonThreeName ="View Products"
        buttonThreeUrl="/flooring/"

        imageTitleFour="Electrical"
        imageFour="/electrical/1.jpg"
        imageFourAlt=""
        imageFourPill=""
        imageFourText="We offer high-quality, professional electrical products and equipment. Supplies and solutions for all your multifamily contstruction projects."
        buttonFourName ="View Products"
        buttonFourUrl="/electrical/"

        imageTitleFive="Appliances"
        imageFive="/appliances/1.jpg"
        imageFiveAlt=""
        imageFivePill=""
        imageFiveText="We offer a full range of kitchen appliances from refrigerators, ovens and more! Products that bring ease and style to your kitchen."
        buttonFiveName ="View Products"
        buttonFiveUrl="/appliances/"

        imageTitleSix="Building Materials"
        imageSix="/building-materials/3.jpg"
        imageSixAlt=""
        imageSixPill=""
        imageSixText="The most common building materials used for different aspects of a home building are concrete, steel, wood, masonry, and stone."
        buttonSixName ="View Products"
        buttonSixUrl="/building-materials/"
      />

    </Layout>
  )
}

/** export */
export default productsPage
